.wrapper{
    width: 100%;
    height: 171%;
    background-color: white;
    // background-image: url(../../../assets/img/notfound.png);
    // background-position: center center;
    // background-size: contain;
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;

    img{
        position: relative;
        width: 100%;
        height: 50%;
        // height: 100%;
        object-fit: cover;
    }
}