.header{
    position: sticky;
    top: 0 ;
    width: 100%;
    height: auto;
    z-index: 100;
    padding: 3px 0;
    background-color: #DBDEE3;
    -webkit-box-shadow: 0px 11px 33px 8px rgba(39,43,71,0.59); 
    box-shadow: 0px 11px 33px 8px rgba(39,43,71,0.59);
 
    &__nav{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header__logo{
            width: 100px;
            height: 100px;        
        }
        
        .header__list{
            display: flex;

            &>li:not(:last-child){
                margin-right: 33px;
            }

            .header__link{
                padding: 7px;
                cursor: pointer;
                font-size: 15px;
                line-height: 1.18;
                letter-spacing: .7px;
                color: #272B47;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                
                @media screen and (max-width: 444px){
                    font-size: 14px;
                    padding: 5px;
                }
            }

            .header__link:hover{
                text-decoration: underline;
            }

            .with__icon{
                display: flex;
                margin-top: -7px;
            }

        }

        // company dropdown
        .company__dropdown{
            z-index: 100;
            background-color: #DBDEE3;
            border-bottom: 3px solid #272B47;
            padding: 0 7px 20px;
            display: none;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;

            @media screen and (max-width: 627px){
                border: none;
                padding:  5px 0 5px 20px;
            }
        }

        .services__dropdown2-p{
            color: #272B47;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-decoration: underline;
            margin-bottom: 15px;

            @media screen and (max-width: 860px){
                font-size: 14px;
            
            }
        }

        .company__dropdown-link,
        .services__dropdown-link{
            color: #272B47;
            display: block;
            margin-top: 20px;

            @media screen and (max-width: 860px){
                font-size: 14px;
            }
            @media screen and (max-width: 627px){
                text-align: start;
                margin: 0;
                
            }
            &:hover{
                text-decoration: underline;
            }
        }

        .dropdown__li:hover .company__dropdown{
            position: absolute;
            display: flex;
            flex-flow: column nowrap;
            text-align: start;
            @media screen and (max-width: 627px){
                position: relative;
            }
        }

        .dropdown__li:hover .services__dropdown{
            opacity: 1;
            visibility: visible;
           
            @media screen and (max-width: 627px){
                display: flex;
            }
        }
       
        // services dropdown
        .services__dropdown{
            z-index: 100;
            background-color: #DBDEE3;
            border-bottom: 3px solid #272B47;
            padding: 3px 13px 13px;
            left: 0px;
            top: 103px;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            transition: all .3s;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -ms-transition: all .3s;
            -o-transition: all .3s;
            display: flex;
            justify-content: space-around;
            width: 100%;

            @media screen and (max-width: 1270px){
                top: 84px;
            }
            @media screen and (max-width: 627px){
                display: none;
                position: relative;
            }
            @media screen and (max-width: 580px){
                flex-wrap: wrap;
            }
        }
        .services__dropdown>div:not(:last-child){
            padding-right: 10px;
            padding-bottom: 7px;
        }
        .header__burger{
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            cursor: pointer;
            display: none;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
    
            i{
                background-color: #272B47;
                width: 30px;
                height: 2px;
                margin: 4px;
                border-radius: 2px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                -ms-border-radius: 2px;
                -o-border-radius: 2px;
            }
    
            .open,
            .close{
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
            }
    
            .open:nth-child(1){
                transform: rotate(45deg) translateY(14px);
                -webkit-transform: rotate(45deg) translateY(14px);
                -moz-transform: rotate(45deg) translateY(14px);
                -ms-transform: rotate(45deg) translateY(14px);
                -o-transform: rotate(45deg) translateY(14px);
            }
    
            .open:nth-child(2){
                opacity: 0;
                transition: all .1s ease;
                -webkit-transition: all .1s ease;
                -moz-transition: all .1s ease;
                -ms-transition: all .1s ease;
                -o-transition: all .1s ease;
            }
    
            .open:nth-child(3){
                transform: rotate(-45deg) translateY(-14px);
                -webkit-transform: rotate(-45deg) translateY(-14px);
                -moz-transform: rotate(-45deg) translateY(-14px);
                -ms-transform: rotate(-45deg) translateY(-14px);
                -o-transform: rotate(-45deg) translateY(-14px);
            }
    
            .close:nth-child(1){
                transform: rotate(0) translateY(0);
                -webkit-transform: rotate(0) translateY(0);
                -moz-transform: rotate(0) translateY(0);
                -ms-transform: rotate(0) translateY(0);
                -o-transform: rotate(0) translateY(0);
            }
    
            .close:nth-child(2){
                opacity: 1;
            }
    
            .close:nth-child(3){
                transform: rotate(0) translateY(0);
                -webkit-transform: rotate(0) translateY(0);
                -moz-transform: rotate(0) translateY(0);
                -ms-transform: rotate(0) translateY(0);
                -o-transform: rotate(0) translateY(0);
            }
    
        }

        .header__social-icon{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: white;
            background-color: #272B47;
            border: 1px solid #272B47;
            padding: 15px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
        }

        .header__social-icon:not(:last-child){
            margin-right: 15px;
        }

        .header__social-icon:hover{
            color: #272B47;
            background-color: #DBDEE3;
            border: 1px solid #272B47;
        }
    
    }
}


//media
@media screen and ( max-width: 1427px){
    .header__nav .header__list{

        &>li:not(:last-child){
            margin-right: 11px;
        }       
    }
}

@media screen and (max-width: 1270px){
    .header__nav .header__burger{
        display: flex;
    }

    .header__nav .header__list{
        z-index: 99;
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
        left: -120%;
        top: 109px;
        background: #DBDEE3;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
    }

    .header__nav .header__list-active{
        position: absolute;
        left: 0%;
        padding: 30px 120px;
        border-bottom: 1px solid #272B47;
    }
    
    
}

@media screen and (max-width: 1130px){
    .header__nav .header__list-active{
        padding: 30px 85px;
    }
}

@media screen and (max-width: 687px){
    .header__nav .services__dropdown {
        top: 77px;
    }

    .header__nav .header__list-active{
        padding: 25px;
    }

    .header__nav .header__list{
        &>li:not(:last-child){
            margin-right: 5px;
        }       
    }
    
}

@media screen and (max-width: 627px){
    .header{
        padding: 7px 0;
    }

    .header__nav .header__logo{
        width: 80px;
        height: 80px;
        margin-right: 7px;
    }

    .header__nav .header__list li:not(:last-child) {
        margin-bottom: 9px;
        padding-top: 1px;
    }

    .header__nav .header__list{
        display: block;
        position: absolute;
        left: -100%;
        top: 96px;
    }

    .header__nav .header__list-active{
        position: absolute;
        left: 0%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .header__nav .header__social-icon {
        padding: 7px;
    }

    .header__nav .header__social-icon:not(:last-child){
        margin-right: 7px;
    }

    .header__nav .services__dropdown {
        top: 0;
    }
}

@media screen and (max-width: 556px){
    .header__nav .header__social-icon {
        padding: 7px;
    }

    .header__nav .header__social-icon:not(:last-child){
        margin-right: 7px;
    }
}

@media screen and (max-width: 444px){
    .header__nav .header__list-active{
        padding-top: 3px;
    }
    .services__dropdown>div{
       width: 100%;
    }
    .services__dropdown2-p{
        margin-top:10px;
    }
}

@media screen and (max-width: 368px){
    .header__nav .header__social-icon {
        padding: 5px;
    }

    .header__nav .header__social-icon:not(:last-child){
        margin-right: 5px;
    }
}

// @media screen and (max-width: 395px){
//     .header__nav .services__dropdown-link {
//         font-size: 12px;
//     }
// }

@media screen and (max-width: 359px){
    .header__nav .services__dropdown-link {
        width: 77%;
    }

    .header__nav .services__dropdown2-p {
        font-size: 10px;
    }
}