.aboutUs{
    background-color: #272b47;
    padding-bottom: 70px;

    @media screen and (max-width: 860px){
        padding-bottom: 30px;
    }

    span{
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 80%;
        color: #DBDEE3;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 1.74;
        padding-bottom: 70px;

        @media screen and (max-width: 860px){
            padding-bottom: 30px;
        }
    }
}

.aboutUs__box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    
    @media screen and (max-width: 773px){
        flex-wrap: wrap;
        justify-content: center;
    }
    

    p{
        color: #DBDEE3;
        font-size: 18px;
        line-height: 2;
        width: 450px;

        @media screen and (max-width: 1157px){
            width: 350px;
        }
        @media screen and (max-width: 895px){
            width: 300px;
            font-size: 15px;
        }
        @media screen and (max-width: 759px){
            width: 100%;
            text-align: center;
        }
    }
}

.aboutUs__box-img{
    width: 450px;
    object-fit: cover;

    @media screen and (max-width: 1157px){
        width: 350px;
    }
    @media screen and (max-width: 895px){
        width: 300px;
    }
    @media screen and (max-width: 759px){
        width: 100%;
    }

    img{
        width: 100%;
        height: 100%;
    }
}