.company{
    width: 100%;
    height: auto;
    overflow-x: hidden;
    padding: 70px 0;
    background-image: url(../../../assets/img/CompanySectionBackground.png);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    background-blend-mode: overlay;
    background-color: #DBDEE3;

    @media screen and (max-width: 860px){
        padding: 30px 0;
    }
    

    &__card{
        display: flex;
        justify-content: space-between;
        
        .company__about{
            flex-basis: 50%;

            h6{
                font-size: 14px;
                letter-spacing: 3px;
                text-transform: uppercase;
                line-height: 1.43;
                color: #2b2a2ade;
                text-decoration: underline;
            }

            .company__span{
                display: block;
                margin: 30px 0;
                width: 55%;

                @media screen and (max-width: 1396px){
                    width: 100%;
                }
                @media screen and (max-width: 860px){
                    font-size: 28px;
                }
                @media screen and (max-width: 626px){
                    font-size: 24px;
                }
            }

            span{
                font-size: 40px;
                line-height: 1.4;
                color: #272b47;

                @media screen and (max-width: 860px){
                    font-size: 28px;
                }
                @media screen and (max-width: 626px){
                    font-size: 24px;
                }
            }

            p{
                color: #696969;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0em;
                line-height: 1.74;
                display: block;
                margin-bottom: 22px;

                @media screen and (max-width: 626px){
                    margin-bottom: 0;
                }
            }

            .company__list{
                border-left: 3px solid #272b47;
                padding-left: 15px;
                margin-left: 15px;
                margin-bottom: 22px;

                li{
                    // color: #2b2a2ade;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.78;
                    color: #333;
                    list-style: circle;
                    margin-left: 15px;

                    @media screen and (max-width: 626px){
                        font-size: 16px;
                    }
                }

            }

        }

        .company__accordion{
            flex-basis: 30%;

            @media screen and (max-width: 1550px){
                flex-basis: 40%;
            }

            @media screen and (max-width: 1240px){
                flex-basis: 33%;
            }

            .company__accordion-item{
                float: right;
                width: 100%;
                margin-bottom: 20px;
            }

            .company__accordion-btn{
                display: flex;
                justify-content: space-between;
                background-color: #DBDEE3;
                -webkit-box-shadow: 0px 0px 15px 2px #272b47; 
                box-shadow: 0px 0px 15px 2px #272b47;
                color: #272b47;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                font-size: 18px;
                padding: 18px 30px;
                font-weight: 500;
                line-height: 1.23;
                cursor: pointer;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;

                &:hover{
                    background-color: #272b47;
                    color: white;
                }

                @media screen and (max-width: 626px){
                    font-size: 16px;
                    padding: 11px;
                }
            }

            .company__accordion-panel{
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0em;
                line-height: 1.74;
                padding: 18px 30px;
                // color: rgb(51, 51, 51);
                color: white;
                background-color: #272b47;
                margin-top: -4px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
            }

        }


    }
}


//media
@media screen and (max-width: 987px){
    .company .company__card{
        flex-wrap: wrap;
    }

    .company .company__card .company__about,
    .company .company__card .company__accordion{
        flex-basis: 100%;
    }

    .company .company__card .company__accordion{
        margin-top: 30px;
    }
}
