.chooseUs{
    background-color: #272b47;
}

.chooseUs__flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 70px;
    color: #DBDEE3;

    @media screen and ( max-width: 860px){
        padding-bottom: 30px;
    }

    h3{
        font-size: 34px;
        line-height: 1.4;
        margin-top: 22px;

        @media screen and ( max-width: 1385px){
            width: 66%;
        }

        @media screen and ( max-width: 1035px){
            width: 100%;
            margin-bottom: 55px;
            font-size: 30px;
        }

        @media screen and ( max-width: 551px){
            text-align: center;
            font-size: 25px;
        }
    }

    @media screen and ( max-width: 1035px){
        flex-wrap: wrap;
        justify-content: center;
        
        .css-qxh7fn {
            width: 80vw;
        }
    }
}

.chooseUs__flex-age{
    display: flex;
    align-items: flex-end;

    @media screen and ( max-width: 1035px){
        justify-content: center;
    }

    span{
        font-size: 180px;
        line-height: .8;
        font-weight: 900;
        margin-right: 5px;

        @media screen and ( max-width: 1035px){
            font-size: 160px;
        }
        @media screen and ( max-width: 404px){
            font-size: 140px;
        }
    }

    p{
        color: white;
        font-size: 12px;
        line-height: 2em;
        letter-spacing: 2px;

        @media screen and ( max-width: 1035px){
            font-size: 10px;
        }

        @media screen and ( max-width: 404px){
            font-size: 8px;
        }
    }
}

.css-14pt78w-MuiSlider-rail {
    color: #DBDEE3;
}

.css-1gv0vcd-MuiSlider-track {
    color: white;
}

.css-eg0mwd-MuiSlider-thumb.Mui-disabled {
    color: #272b47;
    border: 3px solid white;
}

.css-1kz0hui-MuiSlider-valueLabel {
    color: #272b47!important;
    background-color: white!important;
}