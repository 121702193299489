.careers{
    background-color: #272b47;

    .careers__col{
        display: flex;
        color: #DBDEE3;
        align-items: flex-start;
        justify-content: space-between;
        padding: 22px;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;

        &:nth-child(2),&:nth-child(4){
            border: 1px solid #DBDEE3;
        }
        
       
        &:not(:last-child){
            padding-bottom: 30px;
        }

        p{
            width: 44%;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.74;

            @media screen and (max-width: 860px){
                width: 100%;
                display: block;
                margin: 20px 0;
            }
        }

        @media screen and (max-width: 860px){
            flex-direction: column;
        }
        @media screen and (max-width: 404px){
            align-items: center;
            // text-align: center;
        }
    }

    .careers__name{
        flex-basis: 15%;

        @media screen and (max-width: 404px){
            text-align: center;
        }

        h6{
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0em;
            line-height: 1.42;

            @media screen and (max-width: 860px){
                margin-bottom: 20px;
            }
            @media screen and (max-width: 404px){
                margin-bottom: 10px;
            }
        }
    }

}