.description{
    background-color: #272b47;
    padding-bottom: 70px;

    @media screen and (max-width: 860px){
        padding-bottom: 30px;
    }

    &__card-wrap{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .description__card{
            position: relative;
            text-align: center;
            width: 340px;
            height: 200px;
            margin: 0 20px 40px;
            cursor: pointer;

            &:hover .description__card-inner{
                transform: rotateY(180deg);
                -webkit-transform: rotateY(180deg);
                -moz-transform: rotateY(180deg);
                -ms-transform: rotateY(180deg);
                -o-transform: rotateY(180deg);
                transition: transform 1s;
                -webkit-transition: transform 1s;
                -moz-transition: transform 1s;
                -ms-transition: transform 1s;
                -o-transition: transform 1s;
            }

            @media screen and (max-width: 860px){
                width: 240px;
                height: 180px;
                margin: 0 20px 20px;
            }
        }

        .description__card-inner{
            position: absolute;
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
            transition: transform 1s;
            -webkit-transition: transform 1s;
            -moz-transition: transform 1s;
            -ms-transition: transform 1s;
            -o-transition: transform 1s;
        }

        .description__card-face{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            padding: 0 30px;
            
            .description__card-icons{
                width: 150px;
                height: 150px;
                padding: 30px;
                color: #DBDEE3;

                @media screen and (max-width: 860px){
                    width: 50px;
                    padding: 0px;
                }
            }

            h3{
                font-size: 15px;
                font-weight: 300;
                letter-spacing: 2px;
                text-transform: uppercase;
                line-height: 1.74;
                color: #DBDEE3;
                margin-top: 25px;

                @media screen and (max-width: 860px){
                    font-size: 13px;
                    letter-spacing: 1px;
                }
            }

            li{
                font-size: 16px;
                line-height: inherit;
                font-weight: 400;
                color: #272b47;
                text-align: left;
                list-style: circle;
            }
        }

        .back{
            // padding: 40p 20px;
            background: #DBDEE3;
            box-shadow: 0px 0px 11px 1px #DBDEE3;
            transform: rotateY(180deg);
            -webkit-transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            -ms-transform: rotateY(180deg);
            -o-transform: rotateY(180deg);
        }
    }

  
    &__bottom{
        text-align: center;
        border-top: 2px solid #DBDEE3;

        .description__google{
            padding-bottom: 30px;
        }

        p{
            color: white;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.74;      

            @media screen and (max-width: 626px){
                color: #DBDEE3;
                font-size: 13px;
            }
        }
    }

}