.portfolio{
    background-color: #272b47;
    padding: 70px 0;

    @media screen and( max-width:860px){
      padding: 30px 0;
    }
}

.tab-list {  
    padding-bottom: 70px;
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    flex-wrap: wrap;
    color: #272b47;
    line-height: 1.42;
    letter-spacing: 1px;

    @media screen and( max-width:860px){
      padding-bottom: 30px;
    }
  }
  
  .tab-list-item {
    justify-content: center;
    list-style: none;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    background-color: #DBDEE3;
  }
  
  .tab-list-active {
    color: #DBDEE3;
    background: #272B47;
    border: 1px solid #DBDEE3;
}



.portfolioItems{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding-bottom: 4px;
}

.singlePortfolio{
    position: relative;
    width: 25%;
    padding: 30px;
    margin-bottom: 50px;
    color: #272B47;
    background-color: #DBDEE3;
    border: 3px solid #272B47;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease; 

    &:hover{
      transform: scale(0.95);
      -webkit-transform: scale(0.95);
      -moz-transform: scale(0.95);
      -ms-transform: scale(0.95);
      -o-transform: scale(0.95);
      .arrowbtn{
        background-color: #DBDEE3;
        color: #272B47;
        border: 2px solid #272B47;
      }
    }

    @media screen and(max-width: 1170px){
        width: 32%;
      }
      @media screen and (max-width: 750px){
        width: 48%;
      }
      @media screen and (max-width: 480px){
        width: 66%;
      }


    h3{
        justify-content: flex-end;
        text-align: center;
        padding: 30px 0;
    }
  
    .arrowbtn{
        height: 56px;
        width: 56px;
        color: #DBDEE3;
        border: 2px solid #DBDEE3;
        background-color: #272B47;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: -24px;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        transform: translate(-50%);
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -ms-transform: translate(-50%);
        -o-transform: translate(-50%);

        .arrowbtn-icon{
          font-size: 33px;
        }
    }
}
