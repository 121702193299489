.Main{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;

}

.mainCenterWidth{
    width: 32%;
}