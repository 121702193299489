.servicesTopArticle{
    background-image: url(../../../assets/img/ServicesTopArticle.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 373px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 992px){
        height: 353px;
    }
    @media screen and (max-width: 626px){
        height: 202px;
    }

    h1{
        padding: 11px;
        color: #272b47;
        font-size: 44px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        background-blend-mode: overlay;
        background-color: rgba(241, 233, 233, 0.66);
    
        @media screen and ( max-width: 860px){
            padding: 5px;
            font-size: 33px;
            text-align: center;
        }

        @media screen and ( max-width: 500px){
            font-size: 25px;
        }
    }
}