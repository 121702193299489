.highArticle{
    background-image: url(../../assets/img/highArticleBack.png);
    background-size: cover;
    padding: 20px 0;

    // @media screen and (max-width: 1196px){
    //     padding: 70px 0;
    //     background-position: center center;
    // }

    // @media screen and (max-width: 626px){
    //     padding: 44px 0;
    // }

    // @media screen and (max-width: 404px){
    //     padding: 30px 0;
    // }
}

.highArticle__box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1{
        font-size: 64px;
        line-height: 1.13;
        color: #272b47;
        padding-bottom: 22px;

        @media screen and (max-width: 1196px){
            font-size: 54px;
        }

        @media screen and (max-width: 626px){
            font-size: 44px;
        }
        @media screen and (max-width: 404px){
            font-size: 34px;
        }
    }

    p{
        color: #272b47;
        font-size: 24px;
        line-height: 1.67;
        text-decoration: underline;

        @media screen and (max-width: 626px){
            font-size: 20px;
        }
        @media screen and (max-width: 404px){
            font-size: 17px;
        }
    }
}

.highArticle__box-link{
    display: flex;
}

.highArticle__box-redirect{
    color: #636363;
    font-size: 24px;
    line-height: 1.67;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;

    &:hover{
        transform: scale(.9);
        -webkit-transform: scale(.9);
        -moz-transform: scale(.9);
        -ms-transform: scale(.9);
        -o-transform: scale(.9);
    }

    @media screen and (max-width: 626px){
        font-size: 20px;
    }
    @media screen and (max-width: 404px){
        font-size: 17px;
    }
}
