.slierMarketing {
  background-color: #272b47;
  padding-bottom: 70px;
}

.other_title{
  color: #272b47;
}

.sliderPartners{
  background-color: #DBDEE3;
  padding-bottom: 70px;

  @media screen and ( max-width: 860px){
    padding-bottom: 30px;
  }
}

.slide_img {
  width: 101px;
  margin: 0 auto;
}
.slide_img img {
  object-fit: cover;
}
.single_slide{
  h4 {
    color: #DBDEE3;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.25;
    padding: 0 10px 30px 10px;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  background-color: white;
}

@media screen and (max-width: 860px){
  .slide_img {
    width: 88px;
  }

  .slierMarketing {
    padding-bottom: 30px;
  }

  .single_slide h4{
    font-size: 16px;
    padding-bottom: 17px;
  }
}

@media screen and (max-width: 626px){
  .slide_img {
    width: 69px;
  }

  .single_slide h4{
    font-size: 14px;
    padding-bottom: 30px;
  }
}