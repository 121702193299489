*, *:before, *:after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
  width: 100%;
  background: #272b47;
  font-family: Arial, Helvetica, sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 1550px;
  padding: 0 10px;
}

img{
  width: 100%;
  height: 100%;
}

.sec_title{
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding:  70px 0;
  color: #DBDEE3;
  font-size: 40px;
  line-height: 1.4;
  letter-spacing: 2.3px;
  font-weight: 400;

  @media screen and (max-width: 860px){
    padding: 30px 0;
    font-size: 33px;
  }

  @media screen and (max-width: 626px){
    font-size: 24px;
  }
}

.sec_title2{
  padding-top: 100px;
  padding-bottom: 30px;
  
  @media screen and (max-width: 860px){
    padding-top: 30px;
    font-size: 33px;
  }

  @media screen and (max-width: 626px){
    font-size: 24px;
  }
}

.sec_title3{
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 70px;
  padding-top: 20px;
  color: #DBDEE3;
  font-size: 40px;
  line-height: 1.4;
  letter-spacing: 2.3px;
  font-weight: 400;

  @media screen and (max-width: 860px){
    padding-bottom: 30px;
    font-size: 33px;
  }

  @media screen and (max-width: 626px){
    font-size: 24px;
  }
}


.btn-redirect{
  display: block;
  width: auto;
  cursor: pointer;
  background: #DBDEE3;
  color: #272b47;
  border: 1px solid #272b47;
  width: 144px;
  padding: 15px 30px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;

  &:hover{
    background: #272b47;
    color: #DBDEE3;
    border: 1px solid #DBDEE3;
  }

  @media screen and (max-width: 860px){
    width: 133px;
    padding: 10px 25px;
  }

  @media screen and (max-width: 458px){
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.btn-redirect2{
  display: block;
  width: auto;
  cursor: pointer;
  color: #DBDEE3;
  background: #272b47;
  border: 1px solid #DBDEE3;
  width: 144px;
  padding: 15px 30px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;

  &:hover{
    color: #272b47;
    background: #DBDEE3;
    border: 1px solid #272b47;
  }

  @media screen and (max-width: 860px){
    width: 135px;
    padding: 10px 25px;
  }
}

.btn-center{
  @media screen and (max-width: 626px){
    width: 100%;
  }
}

.btn-norm{
  @media screen and (max-width: 626px){
    width: auto;
  }
}

.content{
  background-color: #272b47;
  padding-bottom: 70px;

  @media screen and (max-width: 860px){
    padding-bottom: 30px;
  }

  &__card{
      background-color: #DBDEE3;
      padding: 50px;
      box-shadow: 0px 0px 11px 1px #DBDEE3;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;

      @media screen and(max-width: 860px){
        padding: 22px;
      }

      p,div{
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0em;
          line-height: 1.74;
          color: #272b47;

          @media screen and (max-width: 860px){
            line-height: 1.3;
          }

          ul>li{
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.74;
            color: #272b47;
            list-style: inside;

            @media screen and (max-width: 860px){
              line-height: 1.3;
            }
          }
      }
  }
}

.link-selected{
  font-weight: 600;
  color: #272b47;
  cursor: pointer;
}


// media
@media screen and (max-width: 1551px){
  .container {
    padding: 0 140px;
  }
}

@media screen and (max-width: 1280px){
  .container {
    padding: 0 115px;
  }
}

@media screen and (max-width: 1133px){
  .container {
    padding: 0 80px;
  }
}
@media screen and (max-width: 1551px){
  .container {
    // margin: 0 auto;
    // max-width: 1440px;
    padding: 0 140px;
  }
}
@media screen and (max-width: 1280px){
  .container {
    padding: 0 115px;
  }
}
@media screen and (max-width: 1133px){
  .container {
    padding: 0 80px;
  }
}
@media screen and (max-width: 672px){
  .container {
    padding: 0 16px;
  }
}

@keyframes UnderLine {
  0%   {
    width: 5%;
  }
  100% {
    width: 100%;
  }
}
