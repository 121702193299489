.blogPage{
    padding: 70px 0;

    @media screen and ( max-width: 860px){
        padding: 30px 0;
    }

    .blogPage__post{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #DBDEE3;

        .blogPage__center{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: justify;
            width: 50%;

            @media screen and ( max-width: 1377px){
                width: 88%;
            }

            @media screen and ( max-width: 860px){
                width: 100%;
            }
        }

        .blogPage-img{
            width: 50%;
            height: 353px;

            @media screen and ( max-width: 860px){
                width: 100%;
                height: 252px;
            }

            @media screen and (max-width: 626px){
                height: 181px;
            }
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
            }
        }

        h6{
            display: block;
            margin: 30px 0 20px 0;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 1px;
        }

        h2{
            font-size: 40px;
            line-height: 1.4;
            margin: 0 0 16px;
            word-wrap: break-word;
            text-align: center;

            @media screen and (max-width: 626px){
                font-size: 30px;
            }
        }
        
        span{
            font-size: 15px;
        }

        h4{
            font-size: 19px;
            font-weight: 500;
            line-height: 1.78;
            border-left: 3px solid #DBDEE3;
            border-right: 3px solid #DBDEE3;
            padding: 0 20px;
            margin: 35px;

            @media screen and ( max-width: 860px){
                margin: 15px;
            }
        }

        p{
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.74;
        }

        .blogPage-tags{
            margin: 25px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            @media screen and ( max-width: 860px){
                display: none;
            }
        }

        .blogPage-tags-mark{
            font-size: 22px;
        }

        .blogPage-tags-btn{
            display: block;
            cursor: pointer;
            padding: 7px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
            background-color: #DBDEE3;
            border: 1px solid transparent;
            color: #272b47;
            margin-left: 15px;
    
            @media screen and ( max-width: 458px){
                padding: 4px;
            }
    
            &:hover{
                background-color: #272b47;
                color: #DBDEE3;
                border: 1px solid #DBDEE3;
            }
        }
    }

    .next-prevpart{
        display: flex;
        width: 50%;
        margin: 0 auto;
        justify-content: space-between;

        .prevNext{
            width: 48%;

            .img{
                width: 100%;
                height: 111px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 99;

                @media screen and ( max-width: 859px){
                    margin-top: 22px;
                }

                @media screen and ( max-width: 626px){
                    height: 55px;
                }
                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .prev:after{
                content: 'Previous';
                color: #272b47;
                background-color: #DBDEE3;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 17px;

                @media screen and ( max-width: 626px){
                    font-size: 12px;
                }
            }

            .next:after{
                content: 'Next';
                color: #272b47;
                background-color: #DBDEE3;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 17px;

                @media screen and ( max-width: 626px){
                    font-size: 12px;
                }
            }
        }

    }

}