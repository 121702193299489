.blogCom__form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 70px;
    width: 100%;
    // color: #272b47;

    @media screen and ( max-width: 860px){
        padding-top: 30px;
    }

    .blogCom__form-between{
        display: flex;
        justify-content: space-around;
        width: 50%;

        @media screen and ( max-width: 860px){
            width: 100%;
        }

        input{
            width: 44%;
            background: #DBDEE3;
            padding: 15px;
            cursor: pointer;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;

            &:focus{
                -webkit-box-shadow: 0px 0px 5px 1px #DBDEE3; 
                box-shadow: 0px 0px 5px 1px #DBDEE3;
            }
            
            &::placeholder{
                color: #272b47;
            }

            &:focus::placeholder{
                color: transparent;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
            }
        }
    }

    textarea{
        width: 47%;
        margin: 30px 0;
        padding: 15px;
        resize: none;
        cursor: pointer;
        background: #DBDEE3;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        font-size: 15px;
        font-family: Arial, Helvetica, sans-serif;

        @media screen and ( max-width: 860px){
            width: 94%;
        }

        &:focus{
            -webkit-box-shadow: 0px 0px 5px 1px #DBDEE3; 
            box-shadow: 0px 0px 5px 1px #DBDEE3;
        }

        &::placeholder{
            color: #272b47;
        }

        &:focus::placeholder{
            color: transparent;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
        }
    }

    .btn-center{
        display: block;
        font-size: 15px;
        font-weight: 600;
    }
}