.blog{
    background-color: #272b47;
    padding: 70px 0;

    @media screen and ( max-width: 860px){
        padding: 30px 0;
    }
}

.blog__main{
    display: flex;
    justify-content: space-between;

    @media screen and ( max-width: 728px){
        justify-content: center;
        flex-wrap: wrap;
    }
}

.blog__card{
    display: flex;
    flex-direction: column;
    width: 55%;

    @media screen and ( max-width:1260px){
        width: 66%;
    }

    @media screen and (max-width: 728px){
        width: 100%;
    }
    
    .blog__post{
        width: 88%;
        padding: 50px 0;
        border-top: 1px solid #DBDEE3;

        @media screen and ( max-width: 860px){
            padding: 30px 0;
        }

        @media screen and (max-width: 728px){
            width: 100%;
        }

        @media screen and (max-width: 626px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        .blog__post-img{
            width: 100%;
            height: 454px;

            @media screen and (max-width: 728px){
                height: 353px;
            }

            @media screen and (max-width: 626px){
                height: 252px;
            }

            @media screen and (max-width: 404px){
                height: 171px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 7px;
                -webkit-border-radius: 7px;
                -moz-border-radius: 7px;
                -ms-border-radius: 7px;
                -o-border-radius: 7px;
            }
        }

        h2{
            display: block;
            margin: 25px 0;
            color: white;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-decoration: underline;

            @media screen and ( max-width: 860px){
                font-size: 12px;
                margin: 11px 0;
            }
        }

        .blog__post-title{
            color: #DBDEE3;
            font-size: 40px;
            font-weight: 700;
            letter-spacing: 0em;
            line-height: 1.42;

            @media screen and ( max-width:1260px){
                font-size: 33px;
            }

            @media screen and ( max-width: 860px){
                font-size: 25px;
            }
        }

        .blog__post-date{
            font-size: 20px;
        }

        span{
            display: block;
            margin: 25px 0;
            color: #DBDEE3;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.74;

            @media screen and ( max-width: 860px){
                margin: 11px 0;
            }

            @media screen and (max-width: 626px){
                margin: 0;
            }
        }

        p{
            display: block;
            margin: 25px 0;
            color: #DBDEE3;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.74;

            @media screen and ( max-width: 860px){
                margin: 11px 0;
            }
        }
    }
}

.blog__sidebar{
    width: 23%;

    @media screen and ( max-width:1260px){
        width: 33%;
    }

    @media screen and (max-width: 728px){
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    h4{
        color: white;
        font-size: 34px;
        font-weight: 500;

        @media screen and ( max-width: 860px){
            font-size: 25px;
        }
    }

    ul{
        margin: 25px 0;
        padding-left: 25px;
    }

    ul>li{
        color: #DBDEE3;
        padding-bottom: 10px;
        list-style: circle;
        list-style-position: outside;
        font-size: 25px;

        @media screen and ( max-width: 860px){
            font-size: 20px;
        }
    }

    .blog__sidebar-link{
        color: #DBDEE3;
        font-size: 19px;
        font-weight: 500;
        border-bottom: 1px solid #DBDEE3;
        margin-right: 5px;
    }

    .blog__sidebar-img{
        margin: 25px 0;

        @media screen and (max-width: 728px){
            width: 55%;
        }
    }

    .blog__sidebar-tags{
        margin: 25px 0;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 383px){
            justify-content: center;
        }
    }

    .blog__sidebar-btn{
        display: block;
        cursor: pointer;
        padding: 7px;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        background-color: #DBDEE3;
        border: 1px solid transparent;
        color: #272b47;
        margin-right: 15px;
        margin-bottom: 15px;

        @media screen and ( max-width: 458px){
            padding: 4px;
        }

        &:hover{
            background-color: #272b47;
            color: #DBDEE3;
            border: 1px solid #DBDEE3;
        }
    }

    @media screen and ( max-width: 458px){
        .blog__sidebar-btn:not(:last-child){
            margin-right: 7px;
            margin-bottom: 7px;
        }

        .blog__sidebar-btn:last-child{
            margin-right: 0px;
            margin-bottom: 7px;
        }
    }

}