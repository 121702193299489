.experience{
    background-color: #272b47;
    padding: 70px 0;
    color: #DBDEE3;

    @media screen and ( max-width: 8606px){
        padding: 30px 0;
    }
}

.experience__between{
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and ( max-width: 1034px){
        flex-wrap: wrap;
    }

    @media screen and ( max-width: 626px){
        justify-content: center;
    }
}

.experience__flex{

    h3{
        font-size: 30px;
        line-height: 1.4;
        margin-top: 22px;

        @media screen and ( max-width: 1034px){
            font-size: 25px;
            text-align: center;
        }

        @media screen and ( max-width: 500px){
            font-size: 20px;
        }

    }
}

.experience__flex-age{
    display: flex;
    align-items: flex-end;

    @media screen and ( max-width: 626px){
        align-items: center;
        justify-content: center;
    }

    span{
        font-size: 180px;
        line-height: .8;
        font-weight: 900;
        margin-right: 5px;

        @media screen and ( max-width: 626px){
            font-size: 111px;
        }

        @media screen and ( max-width: 500px){
            font-size: 90px;
        }
    }

    p{
        color: white;
        font-size: 12px;
        line-height: 2em;
        letter-spacing: 2px;

        @media screen and ( max-width: 626px){
            font-size: 10px;
        }

        @media screen and ( max-width: 500px){
            font-size: 7px;
        }
    }
}

.experience__between-p{
    width: 50%;
    font-size: 24px;
    line-height: 1.67;
    font-weight: 800;
    border-left: 3px solid #DBDEE3;
    padding-left: 30px;
    color: #DBDEE3;

    @media screen and ( max-width: 1034px){
        width: 100%;
        margin-top: 25px;
        font-size: 18px;
    }

    @media screen and ( max-width: 626px){
        padding-right: 30px;
        border-right: 3px solid #DBDEE3;
    }
}