.testimonial_slide{
    background-color: #272b47;
    padding-bottom: 70px;
    
    @media screen and (max-width: 860px){
      padding-bottom: 30px;
    }

    .test_singleslide{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      cursor: pointer;
      padding-bottom: 70px;

      @media screen and (max-width: 860px){
        padding-bottom: 30px;
      }

      .slide_text{
        font-size: 16px;
        background-color: #DBDEE3;
        width: 77%;
        padding: 111px 50px 50px 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: justify;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        -webkit-box-shadow: 0px 0px 3px 1px #DBDEE3; 
        box-shadow: 0px 0px 3px 1px #DBDEE3;

        @media screen and (max-width: 1419px){
          padding: 88px 30px 30px 30px;
        }

        @media screen and (max-width: 626px){
          font-size: 14px;
          padding: 88px 11px 11px 11px;
        }
      }
      .name_part{
        text-align: center;
        z-index: 10;
        margin-bottom: -96px;
        @media screen and (max-width: 1419px){
          margin-bottom: -77px;
        }
        .slide_pic {
            width: 100px;
            margin: 0 auto;

            @media screen and (max-width: 1419px){
              width: 77px;
            }
          }
          .slide_pic img {
            object-fit: cover;
            border-radius: 50%;

          }
          p{
            padding: 10px;
            text-decoration: underline;
            text-transform: uppercase;

            @media screen and (max-width: 626px){
              padding: 5px;
            }
          }
    }
    }
    
}
 
  
  