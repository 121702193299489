.lowArticle{
    background-image: url(../../assets/img/lowArticleBack.png);
    background-size: cover;
    
    @media screen and( max-width: 1304px){
        background-position: center center;
    }
}

.lowArticle__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 70px 0;
    
    @media screen and (max-width: 860px){
        flex-wrap: wrap;
        justify-content: center;
        padding: 30px 0;
    }


    p{
        font-size: 20px;
        color: #363636;
        line-height: 1.67;

        @media screen and( max-width: 1304px){
            width: 44%;
        }

        @media screen and (max-width: 860px){
            text-align: center;
            width: 100%;
        }
    }
}

.lowArticle__col{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .tel-icon{
        font-size: 40px;
        color: #272b47;

        @media screen and (max-width: 860px){
            font-size: 33px;
            margin-top: 15px;
        }
    }

    h6{
        color: #272b47;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 1.74;
        margin-top: 15px;
    }

    .lowArticle__col-a{
        color: #272b47;
        font-size: 48px;
        line-height: 1.17;
        display: block;
        margin: 15px 0;

        @media screen and( max-width: 1304px){
            font-size: 44px;
        }

        @media screen and( max-width: 860px){
            font-size: 33px;
        }
    }
}