.faqs{
    background-color: #272b47;
    height: auto;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    padding-bottom: 70px;

    @media screen and (max-width: 860px){
        padding-bottom: 30px;
    }

    .faqs__accordion-card{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1049px){
            flex-wrap: wrap;
        }

        .faqs__accordion{
            flex-basis: 47%;

            @media screen and (max-width: 1274px){
                flex-basis: 48%;
            }
            @media screen and (max-width: 1049px){
                flex-basis: 100%;
            }
        }

        .faqs__accordion{
            

            .faqs__accordion-item{
                float: right;
                width: 100%;
                margin-bottom: 20px;
            }

            .faqs__accordion-btn{
                display: flex;
                justify-content: space-between;
                background-color: #DBDEE3;
                // text-transform: uppercase;
                -webkit-box-shadow: 0px 0px 5px 1px #DBDEE3; 
                box-shadow: 0px 0px 5px 1px #DBDEE3;
                color: #272b47;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                font-size: 17px;
                padding: 18px 30px;
                font-weight: 500;
                line-height: 1.23;
                cursor: pointer;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;

                &:hover{
                    background-color: #272b47;
                    color: #DBDEE3;
                }

                @media screen and (max-width: 626px){
                    padding: 11px;
                  }

                @media screen and (max-width: 404px){
                    font-size: 15px;
                }

            }

            .faqs__accordion-panel{
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0em;
                line-height: 1.74;
                padding: 18px 30px;
                color: #272b47;
                background-color: #DBDEE3;
                margin-top: -4px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
                -webkit-box-shadow: 0px 0px 5px 1px #DBDEE3; 
                box-shadow: 0px 0px 5px 1px #DBDEE3;
            }

        }
    }
}

