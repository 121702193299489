.careersPage{
    padding: 70px 0;

    @media screen and ( max-width:860px){
        padding: 30px 0;
    }
}

.careersPage__main{
    display: flex;
    justify-content: space-between;
    color: #272b47;

    @media screen and ( max-width:1038px){
        flex-wrap: wrap;
    }
}

.careersPage__main-content{
    width: 50%;
    background-color: #DBDEE3;
    -webkit-box-shadow: 0px 0px 15px 2px #DBDEE3;
    box-shadow: 0px 0px 15px 2px #DBDEE3;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    padding: 50px 30px;

    @media screen and ( max-width:1038px){
        width: 100%;
    }

    @media screen and ( max-width:441px){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: justify;
    }

    h2{
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0em;
        line-height: 1.42;
        margin-bottom: 20px;
    }

    p{
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 1.74;
    }

    .decorated_p{
        text-decoration: underline;
        margin-top: 11px;
    }
}

.careersPage__main-img{
    width: 500px;
    height: 100%;

    @media screen and ( max-width:1038px){
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    img{
        object-fit: cover;

        @media screen and ( max-width:1038px){
            width: 300px;
        }

        @media screen and ( max-width:860px){
            width: 250px;
        }

        @media screen and ( max-width:500px){
            width: 200px;
        }
    }
}