.preloader{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #272b47;
    z-index: 999;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    opacity: 1;
    visibility: visible;
}

.loader{
    width: 75px;
    height: 75px;
    border: 10px solid white;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-top-color: #272b47;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    animation: 2s spin infinite linear;
    -webkit-animation: 2s spin infinite linear;
}

.loaded{
    opacity: 0;
    visibility: hidden;
}

@keyframes spin {
    from {
        transform: translate(-50%,-50%) rotate(0deg);
        -webkit-transform: translate(-50%,-50%) rotate(0deg);
        -moz-transform: translate(-50%,-50%) rotate(0deg);
        -ms-transform: translate(-50%,-50%) rotate(0deg);
        -o-transform: translate(-50%,-50%) rotate(0deg);
    }

    to{
        transform: translate(-50%,-50%) rotate(360deg);
        -webkit-transform: translate(-50%,-50%) rotate(360deg);
        -moz-transform: translate(-50%,-50%) rotate(360deg);
        -ms-transform: translate(-50%,-50%) rotate(360deg);
        -o-transform: translate(-50%,-50%) rotate(360deg);
    }
}