.homeBlog{
    padding-top: 70px;
    background-color: #DBDEE3;

    @media screen and (max-width: 860px){
        padding: 30px 0;
    }

    .homeBlog__row{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1479px){
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .homeBlog__col{
        width: 400px;
        height: 535px;
        cursor: pointer;

        @media screen and (max-width: 1479px){
            margin: 0 20px 40px;
        }

        @media screen and (max-width: 860px){
            margin: 20px 0 0 0;
        }

        @media screen and (max-width: 626px){
            width: 353px;
            height: auto;
            text-align: center;
        }

        @media screen and (max-width: 424px){
            width: 282px;
        }

        &:hover .homeBlog__card-img{
            transform: translateY(-15px);
            -webkit-transform: translateY(-15px);
            -moz-transform: translateY(-15px);
            -ms-transform: translateY(-15px);
            -o-transform: translateY(-15px);
        }

        p{
            font-size: 14px;
            color: #696969;
            margin-top: 15px;
        }

        .homeBlog__card-icon{
            color: #272b47;
            font-size: 20px;
            margin-right: 5px;
        }

        h2{
            font-size: 24px;
            color: #272b47;
            line-height: 1.5;
            margin: 15px 0;

            @media screen and (max-width: 626px){
                font-size: 20px;
            }
        }

        h6{
            color: #696969;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.74;
            margin-bottom: 15px;

            @media screen and (max-width: 626px){
                font-size: 14px;
            }
        }
    }

    .homeBlog__card-img{
        width: 400px;   
        height: 250px;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;

        @media screen and (max-width: 626px){
            width: 353px;
            height: 232px;
        }

        @media screen and (max-width: 424px){
            width: 282px;
            height: 202px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    
}