.main{
    width: 100%;
    height: auto;
    // height: 737px;
    background: #272b47;

    &__part{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .main__about{
            display: flex;
            flex-direction: column;
            justify-content: center;

            .main__title{
                white-space: normal;
                font-size: 56px;
                line-height: 72px;
                font-weight: 500;
                color: white;
            }

            .main__p{
                white-space: nowrap;
                font-size: 16px;
                line-height: 32px;
                font-weight: 400;
                color: white;
                letter-spacing: 0px;
                display: block;
                margin: 30px 0;
            }
        }

        .main__img{
            width: 700px;
            overflow: hidden;
            background-image: url(../../../assets/img/main-back.png);
            background-repeat: no-repeat;
            background-size: contain;

            img{
                width: 100%;
                object-fit: cover;
            }
            
        }

    }
}


//media
@media screen and (max-width: 1065px){
    .main__part .main__about .main__title {
        font-size: 44px;
        line-height: 55px;
    }

    .main__part .main__about .main__p {
        font-size: 14px;
        line-height: 22px;
        margin: 22px 0;
    }

    .main__part {
        flex-wrap: wrap;
    }

    .main__about {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin: 0 auto;
        padding: 70px 0;
    }

    .main__part  .main__img{
        width: 500px;
        margin: 0 auto;
        // text-align: center;
    }
}

@media screen and (max-width: 860px){
    .main__part .main__img {
        width: 252px;
    }

    .main__about {
        padding: 30px 0;
    }
}

@media screen and (max-width: 666px){
    .main__part .main__about .main__title {
        font-size: 37px;
    }
}

@media screen and (max-width: 474px){
    .main__part .main__about .main__p {
        margin: 15px 0;
    }

    .main__part .main__about .main__title {
        font-size: 28px;
        line-height: 44px;
    }
}