.portfolioPage{
     padding: 100px 0;
     background: #272b47;
}
.portfolioPageSec {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1058px){
        flex-wrap: wrap;
    }
}


.portTextPart{
    color: #272b47;
    width: 100%;
       @media screen and (max-width: 1058px){
        width: 100%;
      }
    .TextPart{
        padding: 50px 30px;
        background-color: #DBDEE3;
        box-shadow: 0px 0px 10px 1px #DBDEE3;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
    h3{
        text-transform: uppercase;
        font-size: 18px;
        padding-bottom: 30px;
    }
    p,div{
        color: #272b47;
        font-size: 15px;
        line-height: 1.74;

        ul>li{
            list-style-type: disc;
            list-style-position: inside;
        }
    }
}
.portImagePart{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-left: 40px;
 @media screen and (max-width: 1058px){
    margin-left: 0;
    text-align: center;
    margin-top: 40px;
 }
 
}