.packages{
    background-color: #272b47;
    padding: 70px 0;

    @media screen and ( max-width:860px){
        padding: 30px 0;
    }

    .packages__title{

        p{
            display: block;
            text-align: center;
            color: #DBDEE3;
        }
    }

    .packages__box{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and ( max-width:1047px){
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .packages__card{
        background: #DBDEE3;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 25%;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        cursor: pointer;

        &:hover{
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            -webkit-box-shadow: 0px 0px 10px 2px #DBDEE3; 
            box-shadow: 0px 0px 10px 2px #DBDEE3;
        }
        
        h2{
            display: block;
            font-size: 14px;
            line-height: 1.5;
            color: #272b47;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 20px;
        }

        @media screen and ( max-width:1551px){
            flex-basis: 30%;
        }

        @media screen and ( max-width:1047px){
            flex-basis: 55%;

            &:nth-child(2){
                margin: 33px 0;
            }
        }
        @media screen and ( max-width:689px){
            flex-basis: 77%;
        }
        @media screen and ( max-width:404px){
            padding: 22px;

            &:nth-child(2){
                margin: 22px 0;
            }

            h2{
                margin-bottom: 22px;
            }
        }

    }

    .packages__box-img{
        width: 100%;
        height: 100%;
        text-align: center;

        img{
            width: 111px;
            height: 111px;

            @media screen and ( max-width:404px){
                width: 77px;
                height: 77px;
            }
        }
    }

    .packages__card-price{
        display: flex;
        margin: 20px;
        color: #272b47;

        .packages__card-symbol{
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 0;
            align-self: flex-start;
            -webkit-align-self: flex-start;
            -ms-flex-item-align: start;
            margin-right: 3px;
        }

        p{
            font-size: 33px;
            font-weight: 300;
            line-height: 1;

            @media screen and ( max-width: 1551px){
                font-size: 25px;
            }

            // @media screen and ( max-width: 1342px){
            //     font-size: 22px;
            // }
        }

        span{
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 0;
            align-self: flex-end;
            -webkit-align-self: flex-end;
            -ms-flex-item-align: end;
        }

        
        @media screen and ( max-width: 1342px){
            margin-right: 0px;
            margin-left: 0px;
        }

        @media screen and ( max-width:404px){
            margin: 0 0 22px 0;

            p{
                font-size: 20px;
            }
        }
    }

    .packages__card-list{
        margin-top: 20px;
        width: 80%;
        
        li{
            color: #272b47;
            list-style: circle;
            list-style-position: outside;
        }

        li:not(:last-child){
            margin-bottom: 15px;
        }

        @media screen and ( max-width:404px){
            margin-top: 22px;

            li:not(:last-child){
                margin-bottom: 11px;
            }

            li{
                font-size: 15px;
            }
        }
    }
}