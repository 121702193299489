.footer{
    background-color: #272b47;
    padding: 70px 0;

    @media screen and (max-width: 860px){
        padding: 30px 0;
    }
}

.footer__nav{
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
    border-bottom: 2px solid #DBDEE3;

    @media screen and (max-width: 1140px){
        flex-wrap: wrap;
        justify-content: center;
    }
    
    @media screen and (max-width: 860px){
        padding-bottom: 30px;
    }

    .footer__nav-about{
        display: flex;
        flex-direction: column;
        width: 77%;

        @media screen and (max-width: 860px){
            width: 100%;
        }

        .footer__logo{
            width: 110px;
            height: 110px;

            @media screen and (max-width: 626px){
                width: 88px;
                height: 88px;
            }
        }

        .footer__href{
            margin-top: 15px;
            display: flex;
            align-items: center;

            a{
                color: #DBDEE3;

                @media screen and (max-width: 396px){
                    font-size: 12px;
                }
            }
        }

        .footer__href-icon{
            color: #DBDEE3;
            font-size: 22px;
            margin-right: 7px;
        }

        
    }
}

.footer__nav-lists{
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 502px){
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    @media screen and (max-width: 445px){
        flex-direction: column;
    }
}

.footer__nav-list:not(:last-child){
    margin-right: 33px;

    @media screen and (max-width: 502px){
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.footer__nav-list span{
    display: block;
    margin-bottom: 11px;
    font-size: 20px;
    line-height: 1.67;
    text-decoration: underline;
    color: #DBDEE3;

    @media screen and (max-width: 626px){
        font-size: 16px;
    }

    @media screen and (max-width: 445px){
        margin-bottom: 0;
    }
}

.footer__nav-link{
    line-height: 1.4;
    color: #DBDEE3;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    border-bottom: 2px solid transparent;

    &:hover{
        border-bottom: 1px solid white;
    }

    @media screen and (max-width: 626px){
        font-size: 14px;
    }

    @media screen and (max-width: 445px){
        line-height: 1.7;
    }
}

.btn-top{
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-top: 35px;
    border: 1px solid #DBDEE3;
    background: #DBDEE3;
    color: #272b47;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    padding: 7px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
   
    &:hover{
        background-color: #272b47;
        color: #DBDEE3;
    }
}

.footer__i{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    font-weight: 600;

    @media screen and (max-width: 626px){
        font-size: 11px;
        letter-spacing: 0;
    }
}

.footer__bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 35px;

    @media screen and (max-width: 898px){
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;
    }

    p{
        font-size: 14px;
        color: #DBDEE3;

        @media screen and (max-width: 898px){
            margin-bottom: 20px;
        }

        @media screen and (max-width: 626px){
            font-size: 12px;
        }

        @media screen and (max-width: 445px){
            text-align: center;
        }
    }
}

.footer__social-icon{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #272B47;
    background-color: #DBDEE3;
    border: 1px solid #272B47;
    padding: 15px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;

    @media screen and (max-width: 626px){
        font-size: 15px;
    }
}

.footer__social-icon:not(:last-child){
    margin-right: 15px;

    @media screen and (max-width: 626px){
        margin-right: 8px;
    }
}

.footer__social-icon:hover{
    color: #DBDEE3;
    background-color: #272B47;
    border: 1px solid #DBDEE3;
}

@media screen and (max-width: 1140px){
    .footer__nav-about{
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
}
